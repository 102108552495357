<template>
    <section>
        <div class="row mx-0 border-bottom px-2 align-items-center" style="height:45px;">
            <div v-if="id_promocion" class="col pl-0 pr-2">
                <el-select v-model="categoria" placeholder="Seleccionar" class="w-100" size="small" @change="cambioCategoria">
                    <el-option
                    v-for="item in categorias"
                    :key="item.id_categoria"
                    :label="item.categoria"
                    :value="item.id_categoria"
                    />
                </el-select>
            </div>
            <div v-if="id_promocion" class="col pl-2 pr-0 my-auto">
                <el-input v-model="buscar" :disabled="!$can('botones_cedis_promociones_gestionar')" size="small" placeholder="Buscar producto a añadir" class="br-20" @input="search" />
            </div>
        </div>
        <div class="overflow-auto custom-scroll" style="height:calc(100vh - 233px)">
            <div class="row mx-0 py-2 justify-center">
                <div class="mn-pill bg-fondo d-middle text-general px-2" style="height:32px;">
                    <span class="f-600 mr-1">
                        {{ productos.length }}
                    </span>
                    coincidencias en la busqueda
                    <el-tooltip placement="bottom" content="Añadir todos los productos" effect="light">
                        <div class="btn-square-mini ml-1" @click="anadirProducto()">
                            <i class="icon-plus f-12" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <contentLoader v-if="loading || !id_promocion" />
            <div class="row mx-0 justify-center">
                <div class="col-11 px-0">
                    <CardProductoPromocion v-for="(item, idx) in productos" :key="idx" ancho :info="item" class="cr-pointer" :buscador="true" @agregar="agregarProducto" />
                </div>
            </div>
            <div class="d-middle-center px-3 text-center" style="height:40vh">
                <div v-if="buscar=== null && productos.length <=0 && id_promocion" class="">
                    <p class="f-600 f-17">¿Qué producto estás buscando?</p>
                    <img height="115" width="115" src="/img/modales/magnifying_smile.svg" alt="" />
                </div>
                <div v-if="buscar!==null && productos.length <=0" class="">
                    <p class="f-600 f-17">No hemos encontrador coincidencias con tu búsqueda</p>
                    <img height="115" width="115" src="/img/modales/magnifying_sad.svg" alt="" />
                </div>
            </div>
        </div>
        <modalEditarVigencia ref="modalEditarVigencia" @actualizar="actualizarVigencia" />
        <modalProdIncluidos ref="modalProdIncluidos" />
        <modalDetalleProd ref="modalDetalleProd" />
        <modalEliminar ref="modalEliminartienda" titulo="Eliminar tienda de la promoción" mensaje="¿Desea eliminar la tienda de la promoción?" />
        <modal-anadir-producto ref="modalAnadirProductos" @actualizar="asignadosMasivamente" />
        <modal ref="modalAgregarProducto" titulo="Añadir producto" icon="porcent" @guardar="enviarProducto">
            <div class="row m-3 justify-center text-center">
                <span v-if="otra_promo">Este producto se  encuentra en otra promoción</span>
                <span v-if="tipo_promocion==1 || tipo_promocion==3 || tipo_promocion==5">¿Desea Añadir este producto a la promoción?</span>
                <div v-else-if="tipo_promocion==2" class="px-4 col-10">
                    <ValidationObserver ref="validacion">
                        <ValidationProvider v-slot="{errors}" :rules="`required|max_value:${valor}`" name="valor">
                            <label class="pl-3 text-muted">Indique el valor de rebaja del producto </label>
                            <div class="d-middle">
                                <div class="bg-whitesmoke2 py-1 px-1 mr-1 border" style="border-radius:12px 0px 0px 12px">
                                    {{ sigla_actual }}
                                </div>
                                <input-miles-moneda v-model="valor_condicional" :id-moneda="id_moneda" maxlength="13" />
                            </div>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </ValidationObserver>
                    <!-- <ValidationProvider v-slot="{errors}" :rules="`required`" name="valor">
                        <ValidationProvider v-slot="{errors}" :rules="`required|max_value:${valor}`" name="valor">
                            <el-input v-model="valor_condicional" maxlength="8" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider> -->
                </div>
                <div v-else-if="tipo_promocion==4" class="px-4 col-10">
                    Indique el porcentaje de descuento del producto
                    <ValidationObserver ref="validacion2">
                        <ValidationProvider v-slot="{errors}" :rules="`required|max_value:100`" name="descuento">
                            <el-input v-model="valor_condicional" maxlength="8" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </ValidationObserver>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Promocion from '~/services/promociones/promociones_cedis_admin'

export default {
    components: {
        modalEditarVigencia: () => import('~/pages/cedis/ver/promociones/partials/modalEditarVigencia'),
        modalDetalleProd: () => import('~/pages/promociones/partials/modalDetalleProd'),
        modalProdIncluidos: () => import('~/pages/promociones/partials/modalProdIncluidos'),
        modalAnadirProducto: () => import('../partials/modalAnadirProductos')
    },
    data(){
        return {
            id_cedi: parseInt(this.$route.params.id_cedis),
            buscar: null,
            loading: false,
            productos: [],
            id_producto: null,
            descuento: null,
            promo_valor: null,
            valor: null,
            valor_condicional: null,
            otra_promo: false,
            value: '',
            categorias: [],
            categoria: ''
        }
    },
    computed:{
        ...mapGetters({
            id_promocion: 'promociones/promociones_cedis/id_promocion',
            tipo_promocion: 'promociones/promociones_cedis/tipo_promocion',
            promocion: 'promociones/promociones_cedis/promocion',
            info_cedis:'cedis/info_cedis',
        }),
        sigla_actual(){
            var sigla = '-'
            let moneda = this.monedas.find(e => e.id=== this.info_cedis.idm_moneda )
            if(moneda){
                sigla = moneda.sigla
            }
            return sigla
        },
        id_moneda(){
            let moneda = 0
            if(this.info_cedis){
                moneda = this.info_cedis.idm_moneda
            }
            return moneda
        },
    },
    watch:{
        id_promocion(){
            this.getCategorias()
        }
    },
    methods: {
        async getCategorias(){
            try {
                const {data} = await Promocion.getCategorias(this.id_cedi, this.id_promocion)
                this.categorias = data.categorias;
                if(data.categorias.length > 0){
                    this.categoria = data.categorias[0].id_categoria;
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        agregarTienda(){
            this.$refs.modalAgregarTienda.toggle('crear')
        },
        editarTienda(){
            this.$refs.modalAgregarTienda.toggle('editar')
        },
        eliminarTienda(){
            this.$refs.modalEliminartienda.toggle()
        },
        prodIncluidos(){
            this.$refs.modalProdIncluidos.toggle()
        },
        detalleProducto(){
            this.$refs.modalDetalleProd.toggle()
        },
        actualizarVigencia(data, desdeBuscador){
            this.promocion.inicio = data.inicio
            this.promocion.fin = data.fin
            this.promocion.estado = data.estado
            this.promocion.id_cedi_promocion = data.id
            this.listarPromociones()
            if(desdeBuscador){
                this.$refs.modalAgregarProducto.toggle()
            }
        },
        anadirProducto(){
            let data = _.cloneDeep(this.productos)
            let cantidad = data.length;
            if(cantidad < 1 )return;
            let productosAsignar = [];
            for(let i = 0; i < cantidad; i++){
                if(!data[i].agregado){
                    let objeto = data[i];
                    objeto.checked = false;
                    productosAsignar.push(objeto);
                }
            }
            if(productosAsignar.length < 1)return
            this.$refs.modalAnadirProductos.toggle(productosAsignar);
        },
        agregarProducto(producto){
            this.id_producto = producto.id
            this.valor = producto.valor
            this.otra_promo = producto.otra_promo
            if(!this.promocion.id_cedi_promocion){ // no existe el cedis_promociones
                this.$refs.modalEditarVigencia.toggle(this.promocion, true)
            }else{
                setTimeout(() => {

                    this.$refs.modalAgregarProducto.toggle()
                }, 0);
            }
        },
        search(){
            this.delay(this.listarProductosBuscador,600)
        },
        async listarProductosBuscador(){
            try {
                var data = null
                if(this.buscar !== null || this.buscar !== ''){
                    this.loading = true
                    let enviar = {
                        text: this.buscar,
                        id_promocion: this.id_promocion,
                        id_cedi: this.id_cedi,
                        categoria: this.categoria
                    }
                    var {data} = await Promocion.buscadorProductosPromo(enviar)
                    this.loading = false
                    this.productos = data.productos
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async enviarProducto(){
            try {
                var enviar = {
                    id_cedis: this.id_cedi,
                    id_producto : this.id_producto,
                    tipo : this.tipo_promocion
                }
                var valid = false
                if(this.tipo_promocion == 2){
                    console.log('this.tipo_promocion', this.tipo_promocion, 'valor', this.promo_valor);
                    enviar.promo_valor= this.valor_condicional
                    valid = await this.$refs.validacion.validate()
                    if(!valid){
                        this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                        return false
                    }
                }
                if(this.tipo_promocion == 4){
                    enviar.descuento = this.valor_condicional,
                    valid = await this.$refs.validacion2.validate()
                    if(!valid){
                        this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                        return false
                    }
                }
                var data = null
                enviar.id_promocion = this.id_promocion
                var {data}  = await Promocion.agregarProductosAdmin(enviar)

                this.listarProductosBuscador()
                this.limpiar()
                this.notificacion('Mensaje', 'Producto agregado', 'success')
                const send ={
                    id_promocion: this.id_promocion,
                    id_cedi: this.id_cedi
                }
                this.$refs.modalAgregarProducto.toggle()
                await this.$store.dispatch('promociones/promociones_cedis/action_listar_productos_agregados', send )
                this.listarPromociones()

            } catch (e){
                this.error_catch(e)
            }

        },
        async listarPromociones(){
            try {
                const enviar = {
                    id_cedis: this.id_cedi
                }
                this.loading = true
                await this.$store.dispatch('promociones/promociones_cedis/action_listar_admin_promociones', enviar)
                this.loading = false
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.descuento = null
            this.promo_valor = null
            this.valor = null
            this.valor_condicional = null
        },

        resetearBuscador(){
            this.buscar = null
            this.loading = false
            this.productos = []
            this.id_producto = null
            this.descuento = null
            this.promo_valor = null
            this.valor = null
        },
        async asignadosMasivamente(){
            this.listarProductosBuscador()
            this.limpiar()
            const send = {
                id_promocion: this.id_promocion,
                id_cedi: this.id_cedi
            }
            await this.$store.dispatch('promociones/promociones_cedis/action_listar_productos_agregados', send )
            this.listarPromociones()
        },
        cambioCategoria(){
            this.resetearBuscador();
        },
    }
}
</script>
